.contact-container {
  width: 75%;
  @media (max-width: 420px) {
    width: 100%;
    padding:0px 15px 15px 15px;
  }
  .get-back {
    padding: 10px 15px;
    color: #888484;
    .fas {
      color: #fe6d00;
      padding: 5px;
    }
  }
  #contact-form {
    input {
      margin: 5px 0px;
      padding: 10px;
      height: 50px;
      &:focus {
        border: 2px solid #fe6d00;
        box-shadow: none;
      }
    }
    textarea {
      margin: 5px 0px;
      padding: 10px;
      &:focus {
        border: 2px solid #fe6d00;
        box-shadow: none;
      }
    }
    .spinner-container {
      height: 50px;
      padding: 12px 17px;
      background: #fe6d00;
      border-radius: 2px;
      width: 90px;
      .spinner-border {
        color: #fff;
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    .g-recaptcha{
      margin:15px;
      &.error{
        border: 3px solid red;
      }
    }
    .button-block {
      display: flex;
      justify-content: space-between;
      button {
        width: 90px;
        float: left;
        color: #fe6d00;
        background: #fff;
        padding: 12px 17px;
        border: 2px solid #fe6d00;
        font-size: 14px;
        height: 50px;
        letter-spacing: 3px;
        text-transform: uppercase;
        border-radius: 2px;
        display: inline-block;
        text-align: center;
        cursor: pointer;
        box-shadow: inset 0 0 0 0 #fe6d00;
        -webkit-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
        &:hover {
          box-shadow: inset 100px 0 0 0 #fe6d00;
          color: #fff;
        }
      }
    }
  }
  .find-me {
    display: flex;
    justify-content: space-between;
    text-align: left;
    color: #a2a2a2;
    a.icon  {
      vertical-align: middle;
    }
    .fas,
    .fab {
      color: #fe6d00;
      padding: 10px;
      font-size: 20px;
    }
  }
}
