@import url('https://fonts.googleapis.com/css?family=Alatsi&display=swap');
@import url('https://fonts.googleapis.com/css?family=Oswald:500&display=swap');
@import url('https://fonts.googleapis.com/css?family=Markazi+Text:400,700&display=swap');
body {
  margin: 0;
  font-family: 'Alatsi', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:#212529
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1{
  font-family: 'Oswald', sans-serif;
  color:#212529
}

.paragraph{
  font-family: 'Markazi Text', serif;
  font-size: 25px;
  color: #212529;
}
