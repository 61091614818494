.main-content {
  .backdrop {
    height: 100%;
    object-fit: cover;
    filter: contrast(1.3);
    position: absolute;
    top: 24px;
    left: 0;
    z-index: -1;
  }
  .background-image::before {
    z-index: -1;
    content: "";
    background-image: url("https://img4.goodfon.com/wallpaper/nbig/7/f2/abstract-background-design-yellow-squares-white-abstraktsiia.jpg");
    background-size: 100%;
    opacity: 0.1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    -moz-transition: opacity 0.5s ease-in-out;
    -webkit-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
  }
  .background-image.no-background::before {
    opacity: 0;
    -moz-transition: opacity 0.5s ease-in-out;
    -webkit-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
  }
  h1 {
    display: block;
    font-size: 6rem;
  }
  .name-container {
    display: flex;
    height: 100vh;
    vertical-align: middle;
    align-items: center;
    .title {
      color: #fe6d00;
    }
  }
  .btn-outline-dark:hover {
    background: black;
  }
  .subheading {
    letter-spacing: 3px;
    padding-top: 50px;
  }
  .subheading-underline {
    width: 10%;
    border-top: 3px solid #fe6d00;
  }
  .headline {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 420px) {
      display: block;
    }
    img {
      width: 20%;
	  padding: 30px;
	  @media (max-width: 420px) {
		width: 80%;
		padding: 40px;
	  }
    }
  }
  .carousel-image {
	border-radius: 10px;
	width:75%;
	background-color: white;
	box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0), 0px 1px 1px 0px rgba(0, 0, 0, 0), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  }
}
