@import url(https://fonts.googleapis.com/css?family=Alatsi&display=swap);
@import url(https://fonts.googleapis.com/css?family=Oswald:500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Markazi+Text:400,700&display=swap);
body {
  margin: 0;
  font-family: 'Alatsi', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #212529; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

h1 {
  font-family: 'Oswald', sans-serif;
  color: #212529; }

.paragraph {
  font-family: 'Markazi Text', serif;
  font-size: 25px;
  color: #212529; }

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

nav.navbar {
  background-color: white;
  position: fixed;
  width: 100%;
  z-index: 99;
  box-shadow: 0 4px 2px -2px #ececec; }
  nav.navbar .navbar-brand {
    padding: 5px 8px;
    border: 3px solid #fe6d00;
    color: #fe6d00; }
  nav.navbar .collapse {
    flex-direction: row-reverse; }
  nav.navbar ul {
    z-index: 999; }
    nav.navbar ul li {
      padding: 5px; }
      nav.navbar ul li a.nav-link:hover {
        color: #fe6d00 !important; }

.main-content .backdrop {
  height: 100%;
  object-fit: cover;
  -webkit-filter: contrast(1.3);
          filter: contrast(1.3);
  position: absolute;
  top: 24px;
  left: 0;
  z-index: -1; }

.main-content .background-image::before {
  z-index: -1;
  content: "";
  background-image: url("https://img4.goodfon.com/wallpaper/nbig/7/f2/abstract-background-design-yellow-squares-white-abstraktsiia.jpg");
  background-size: 100%;
  opacity: 0.1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  -webkit-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out; }

.main-content .background-image.no-background::before {
  opacity: 0;
  -webkit-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out; }

.main-content h1 {
  display: block;
  font-size: 6rem; }

.main-content .name-container {
  display: flex;
  height: 100vh;
  vertical-align: middle;
  align-items: center; }
  .main-content .name-container .title {
    color: #fe6d00; }

.main-content .btn-outline-dark:hover {
  background: black; }

.main-content .subheading {
  letter-spacing: 3px;
  padding-top: 50px; }

.main-content .subheading-underline {
  width: 10%;
  border-top: 3px solid #fe6d00; }

.main-content .headline {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 420px) {
    .main-content .headline {
      display: block; } }
  .main-content .headline img {
    width: 20%;
    padding: 30px; }
    @media (max-width: 420px) {
      .main-content .headline img {
        width: 80%;
        padding: 40px; } }

.main-content .carousel-image {
  border-radius: 10px;
  width: 75%;
  background-color: white;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0), 0px 1px 1px 0px rgba(0, 0, 0, 0), 0px 1px 3px 0px rgba(0, 0, 0, 0.1); }

.block-container {
  min-height: 100vh;
  display: flex; }

.about {
  width: 75%;
  margin: auto; }
  @media (max-width: 420px) {
    .about {
      width: 100%; } }
  .about .quote {
    font-style: italic; }
  .about .contrast {
    color: #fe6d00; }
  .about .profile-image {
    border-radius: 200px;
    width: 200px;
    height: 200px; }

.expertise .skills {
  display: inline-flex;
  flex-flow: column; }

.expertise div .fa,
.expertise div .fas {
  height: 150px;
  width: 150px;
  color: transparent;
  font-size: 80px;
  padding: 30px 20px;
  text-shadow: 0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00;
  color: white; }

.expertise div .skill-container {
  padding-top: 30px; }
  .expertise div .skill-container .skill-title {
    text-align: left;
    font-size: 30px;
    font-weight: 600; }
  .expertise div .skill-container .skill-paragraph {
    text-align: justify; }

.company-title {
  color: black;
  font-size: 20px;
  font-family: Alatsi; }
  .company-title:hover {
    -webkit-text-decoration-style: none !important;
            text-decoration-style: none !important;
    color: black; }

.contact-container {
  width: 75%; }
  @media (max-width: 420px) {
    .contact-container {
      width: 100%;
      padding: 0px 15px 15px 15px; } }
  .contact-container .get-back {
    padding: 10px 15px;
    color: #888484; }
    .contact-container .get-back .fas {
      color: #fe6d00;
      padding: 5px; }
  .contact-container #contact-form input {
    margin: 5px 0px;
    padding: 10px;
    height: 50px; }
    .contact-container #contact-form input:focus {
      border: 2px solid #fe6d00;
      box-shadow: none; }
  .contact-container #contact-form textarea {
    margin: 5px 0px;
    padding: 10px; }
    .contact-container #contact-form textarea:focus {
      border: 2px solid #fe6d00;
      box-shadow: none; }
  .contact-container #contact-form .spinner-container {
    height: 50px;
    padding: 12px 17px;
    background: #fe6d00;
    border-radius: 2px;
    width: 90px; }
    .contact-container #contact-form .spinner-container .spinner-border {
      color: #fff;
      width: 1.5rem;
      height: 1.5rem; }
  .contact-container #contact-form .g-recaptcha {
    margin: 15px; }
    .contact-container #contact-form .g-recaptcha.error {
      border: 3px solid red; }
  .contact-container #contact-form .button-block {
    display: flex;
    justify-content: space-between; }
    .contact-container #contact-form .button-block button {
      width: 90px;
      float: left;
      color: #fe6d00;
      background: #fff;
      padding: 12px 17px;
      border: 2px solid #fe6d00;
      font-size: 14px;
      height: 50px;
      letter-spacing: 3px;
      text-transform: uppercase;
      border-radius: 2px;
      display: inline-block;
      text-align: center;
      cursor: pointer;
      box-shadow: inset 0 0 0 0 #fe6d00;
      -webkit-transition: all ease 0.8s;
      transition: all ease 0.8s; }
      .contact-container #contact-form .button-block button:hover {
        box-shadow: inset 100px 0 0 0 #fe6d00;
        color: #fff; }
  .contact-container .find-me {
    display: flex;
    justify-content: space-between;
    text-align: left;
    color: #a2a2a2; }
    .contact-container .find-me a.icon {
      vertical-align: middle; }
    .contact-container .find-me .fas,
    .contact-container .find-me .fab {
      color: #fe6d00;
      padding: 10px;
      font-size: 20px; }

.image-element-class {
  list-style: none;
  padding: 10px; }
  .image-element-class img {
    width: 500px;
    height: 333px;
    border-radius: 5px;
    object-fit: cover;
    box-shadow: 0 4px 2px -2px #ececec; }

.my-gallery-class {
  margin: 20px; }

