.expertise {
    .skills {
      display: inline-flex;
      flex-flow: column;
    }
    div {
      .fa,
      .fas {
        height: 150px;
        width: 150px;
        color: transparent;
        font-size: 80px;
        padding: 30px 20px;
        text-shadow: 0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00,0px 0px 2px #fe6d00;
        color: white;  
      }
      .skill-container {
        padding-top: 30px;
        .skill-title {
          text-align: left;
          font-size: 30px;
          font-weight: 600;
        }
        .skill-paragraph {
          text-align: justify;
        }
      }
    }
  }